import React, { useEffect, useState } from "react";
import { ConceptBubble } from "../parts/ConceptBubble";
import { StaticImage } from "gatsby-plugin-image";

const data = {
  jikabaisen: {
    title: "自家焙煎",
    detail: `
      私たちが焙煎で大切にしているのは「バランス」と「テロワール」。<br class="hidden md:block" />
      焙煎プロファイルはコーヒー豆毎によって、テイスティングと調整を納得いくまで繰り返し、<br class="hidden md:block" />目指す味を作り上げていきます。
    `,
  },
  barista_training: {
    title: "トータルサポート",
    detail: `
      どんなに良いコーヒー豆でも、抽出によってコーヒーは簡単に「不味く」もなります。<br class="hidden md:block" />
      お取引様、お取引先様のお客様にも「コーヒー美味しい」と喜んでいただけるよう、<br class="hidden md:block" />丁寧なサポートをさせていただいております。
    `,
  },
  specialty_coffee: {
    title: "スペシャルティコーヒー",
    detail: `
      スペシャルティコーヒーとは、際立つ風味特性があり、<br class="hidden md:block" />
      世界基準をクリアすることができた高品質なコーヒーになります。<br class="hidden md:block" />
      「小さな幸せ」をお届けするためにも、先ずは美味しいということが必須条件。<br class="hidden md:block" />
      そのために、私たちはスペシャルティコーヒーを専門に取り扱っています。
    `,
  },
  wholesales: {
    title: "卸売事業",
    detail: `
      カフェやレストラン、美容室やアパレルショップなど、コーヒーをご提供される会社、<br class="hidden md:block" />
      店舗様へコーヒー豆の卸販売をさせていただいています。<br class="hidden md:block" />
      コーヒーを通じて、『小さな幸せ』を届ける。そんなお手伝いをさせていただいています。
    `,
  },
};

type ConceptDetailBubblesProps = {
  selected:
    | "jikabaisen"
    | "barista_training"
    | "specialty_coffee"
    | "wholesales";
};

export const ConceptDetailBubbles: React.FC<ConceptDetailBubblesProps> = (
  props
) => {
  const { selected } = props;
  const [selectedDetail, setSelectedDetail] = useState<
    "jikabaisen" | "barista_training" | "specialty_coffee" | "wholesales"
  >("jikabaisen");

  useEffect(() => {
    setSelectedDetail(selected);
  }, [selected]);

  return (
    <>
      <div className="overflow-x-scroll w-[100%] mb-10 hidden-scrollbar">
        <div
          className="flex md:justify-center w-fit md:w-[100%]
            md:max-w-[980px] md:mx-auto pl-[15px] md:pl-0
            after:content-[''] after:w-[15px] after:h-[1px] after:inline-block
            "
        >
          <ConceptBubble
            title="自家焙煎"
            position=""
            to=""
            imageClass="w-[100%] md:w-[160px]"
            wrapperClass="w-[100px] md:w-[20.4%] mr-[44px] md:mr-[6.13%]"
            textClass="text-sm md:text-md leading-[1.5]"
            onClick={() => setSelectedDetail("jikabaisen")}
            isActive={selectedDetail === "jikabaisen"}
          >
            <StaticImage
              src="../../images/top/top_concept_img_01.png"
              alt="自家焙煎"
            />
          </ConceptBubble>

          <ConceptBubble
            title="トータルサポート"
            position=""
            to=""
            imageClass="w-[100%] md:w-[160px]"
            wrapperClass="w-[100px] md:w-[20.4%] mr-[44px] md:mr-[6.13%]"
            textClass="text-sm md:text-md leading-[1.5]"
            onClick={() => setSelectedDetail("barista_training")}
            isActive={selectedDetail === "barista_training"}
          >
            <StaticImage
              src="../../images/top/top_concept_img_02.png"
              alt="トータルサポート"
            />
          </ConceptBubble>
          <ConceptBubble
            title="スペシャルティ<br class='md:hidden' />コーヒー"
            position=""
            to=""
            imageClass="w-[100%] md:w-[160px]"
            wrapperClass="w-[100px] md:w-[20.4%] mr-[44px] md:mr-[6.13%]"
            textClass="text-sm md:text-md leading-[1.5]"
            onClick={() => setSelectedDetail("specialty_coffee")}
            isActive={selectedDetail === "specialty_coffee"}
          >
            <StaticImage
              src="../../images/top/top_concept_img_03.png"
              alt="スペシャルティコーヒー"
            />
          </ConceptBubble>
          <ConceptBubble
            title="卸売事業"
            position=""
            to=""
            imageClass="w-[100%] md:w-[160px]"
            wrapperClass="w-[100px] md:w-[20.4%]"
            textClass="text-sm md:text-md leading-[1.5]"
            onClick={() => setSelectedDetail("wholesales")}
            isActive={selectedDetail === "wholesales"}
          >
            <StaticImage
              src="../../images/top/top_concept_img_04.png"
              alt="卸売事業"
            />
          </ConceptBubble>
        </div>
      </div>
      <div className="px-5">
        <div
          className="max-w-[680px] mx-auto border-primary border-[2px] rounded-default
          p-5 text-sm font-bold antialiased relative"
        >
          <h3
            className="bg-primary text-white font-bold text-sm
            rounded-[5px] inline-block px-[30px] py-2 leading-none absolute top-0 left-6 -translate-y-1/2"
          >
            {data[selectedDetail].title}
          </h3>
          <p
            className="md:text-center lading-[1.67]"
            dangerouslySetInnerHTML={{ __html: data[selectedDetail].detail }}
          ></p>
        </div>
      </div>
    </>
  );
};
