import * as React from "react";
import { BaseTableRow, BaseTableRowProps } from "../parts/BaseTableRow";

type BaseTableTemplateProps = {
  data: BaseTableRowProps[];
  isReplaceBr?: boolean;
};

export const BaseTableTemplate: React.FC<BaseTableTemplateProps> = (props) => {
  const { data, isReplaceBr = false } = props;

  return (
    <div className="max-w-[720px] mx-auto px-5">
      <dl className="border-secondary border-2 rounded-default py-10 px-5">
        {data.map((rowData, index) => (
          <BaseTableRow
            key={index}
            title={rowData.title}
            contents={rowData.contents}
            isReplaceBr={isReplaceBr}
          />
        ))}
      </dl>
    </div>
  );
};
