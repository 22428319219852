import React from "react";

type TextTitleProps = {
  children: React.ReactNode;
};

export const TextTitle: React.FC<TextTitleProps> = (props) => {
  return (
    <h2 className="text-center text-md md:text-xxxl font-bold mb-5 md:mb-[48px]">
      {props.children}
    </h2>
  );
};
