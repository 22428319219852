import React from "react";

export type BaseTableRowProps = {
  title: string;
  contents: string | null;
  isReplaceBr?: boolean;
};
export const BaseTableRow: React.FC<BaseTableRowProps> = (props) => {
  const { title, contents, isReplaceBr = false } = props;

  return (
    <div className="block md:flex [&:not(:last-of-type)]:pb-4 [&:not(:last-of-type)]:border-b border-line [&:not(:last-of-type)]:mb-4 [&:not(:last-of-type)]:md:mb-4">
      <dt className="flex items-center md:w-[170px] text-base font-bold antialiased" dangerouslySetInnerHTML={{__html: title}}></dt>
      {isReplaceBr ? (
        <dd className="md:w-[calc(100%_-_170px)] text-sm md:text-base font-medium antialiased leading-loose md:leading-[1.75] flex items-center">
          {contents &&
            contents.split("\n").map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
        </dd>
      ) : (
        <dd
          className="md:w-[calc(100%_-_170px)] text-sm md:text-base font-medium antialiased leading-loose md:leading-[1.75] flex items-center"
          dangerouslySetInnerHTML={{ __html: contents || "" }}
        ></dd>
      )}
    </div>
  );
};
