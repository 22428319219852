import * as React from "react";
import { BaseLayout } from "../components/views/layouts/BaseLayout";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "../hooks/useWindowSize";
import { UnderContentMv } from "../components/templates/UnderContentMv";
import { DotChar } from "../components/parts/DotChar";
import { ConceptDetailBubbles } from "../components/views/ConceptDetailBubbles";
import { TextTitle } from "../components/parts/TextTitle";
import { BaseTableRowProps } from "../components/parts/BaseTableRow";
import { BaseTableTemplate } from "../components/templates/BaseTableTemplate";
import { BcItem } from "../components/views/Breadcrumb";
import { useEffect, useState } from "react";
import { getParam } from "../utils/UrlUtils";

const bcList: BcItem[] = [{ href: "", text: "CONCEPT" }];

const tableData: BaseTableRowProps[] = [
  {
    title: "店舗名",
    contents: `
        LOG COFFEE ROASTERS
        <br class="md:hidden" />
        （ログ コーヒー ロースターズ）
    `,
  },
  {
    title: "代表",
    contents: `武本 直樹`,
  },
  {
    title: `適格請求書発行事業者<br class="hidden md:inline">登録番号`,
    contents: `T7810723456990`,
  },
  {
    title: "所在地",
    contents: `
        〒753-0086
        <br />
        山口県山口市中市町４−１４
      `,
  },
  {
    title: "電話番号",
    contents: `083-902-1371`,
  },
  {
    title: "営業時間",
    contents: `営業時間：１１：００ー１９：００`,
  },
  {
    title: "定休日",
    contents: `
        月曜日
        <br class="md:hidden" />
        （※月曜祝日の時は通常営業、火曜が振替店休日）
      `,
  },
];

const ConceptPage = () => {
  const [windowWidth, windowHeight] = useWindowSize();
  const [initialDetail, setInitialDetail] = useState<
    "jikabaisen" | "barista_training" | "specialty_coffee" | "wholesales"
  >("jikabaisen");

  useEffect(() => {
    const detailQuery = getParam("show");
    if (
      detailQuery === "jikabaisen" ||
      detailQuery === "barista_training" ||
      detailQuery === "specialty_coffee" ||
      detailQuery === "wholesales"
    ) {
      setInitialDetail(detailQuery);
    }
  }, []);

  return (
    <BaseLayout
      bcList={bcList}
      pageUrl="/concept"
      pageTitle="CONCEPT"
      pageDescription="「一杯のコーヒーで、小さな幸せを」。ロースター、バリスタとして、 コーヒーを通して、みなさまの日常に『小さな幸せ』 をお届けいたします。"
    >
      <UnderContentMv jaTitle="LOG COFFEE ROASTERS について" enTitle="CONCEPT">
        {windowWidth < 480 ? (
          <StaticImage
            src="../images/mv/concept.jpg"
            alt="CONCEPT メイン画像"
          />
        ) : (
          <div className="h-[420px] lg:h-[580px]">
            <StaticImage
              src="../images/mv/concept_pc.jpg"
              alt="CONCEPT メイン画像"
              className="absolute inset-y-0 m-auto"
            />
          </div>
        )}
      </UnderContentMv>
      <section
        className="mb-[40px] md:mb-[80px]"
        data-page-animation={true}
        data-type-fade={true}
      >
        <h2 className="text-md md:text-xl lg:text-xxl text-center font-bold antialiased mb-5 md:mb-8">
          一杯のコーヒーで、小さな{" "}
          <span className="text-[1.25em] text-secondary">
            <DotChar dotSize="before:w-[6px] before:h-[6px]">幸</DotChar>
            <DotChar dotSize="before:w-[6px] before:h-[6px]">せ</DotChar>
          </span>{" "}
          を
        </h2>
        <p className="text-center">
          ロースター、バリスタとして、 <br className="md:hidden" />
          コーヒーを通して、みなさまの日常に
          <br />
          『小さな幸せ』 をお届けいたします
        </p>
      </section>
      <section
        className="mb-7 md:mb-[80px]"
        data-page-animation={true}
        data-type-fade={true}
      >
        <div className="max-w-[480px] md:max-w-[1240px] mx-auto px-5">
          <div className="md:flex md:items-center">
            <div className="md:w-[46.7%] md:pr-[6.7%]">
              <h2 className="text-md lg:text-lg xl:text-xxl mb-5 md:mb-7 font-bold">
                コーヒーのある暮らし
              </h2>
              <p className="mb-7">
                一人でゆっくりと過ごす時、家族や友人と過ごす時、仕事や勉強をしている時、どんな時でも、生活の傍ら（かたわら）にはコーヒーがあります。
                <br />
                日常のコーヒーがより美味しくなったら、より楽しくなったなら、生活をもっと豊かにすることができる。
              </p>
              {windowWidth >= 768 && (
                <p>
                  私たちは、コーヒーにはその力があると信じ、ロースター、バリスタとしてコーヒーと向き合っています。
                </p>
              )}
            </div>
            <div className="md:w-[53.3%] mb-7 md:mb-0">
              <StaticImage
                src="../images/concept/image_01.png"
                alt="コーヒーのある暮らし"
              />
            </div>
            {windowWidth < 768 && (
              <p>
                私たちは、コーヒーにはその力があると信じ、ロースター、バリスタとしてコーヒーと向き合っています。
              </p>
            )}
          </div>
        </div>
      </section>
      <section
        className="mb-[170px] md:mb-[210px]"
        data-page-animation={true}
        data-type-fade={true}
      >
        <div className="max-w-[480px] md:max-w-[1240px] mx-auto px-5">
          <div className="md:flex md:flex-row-reverse md:justify-end md:items-center">
            <div className="md:w-[46.7%] md:pl-[6.7%]">
              <h2 className="text-md md:text-xxl mb-5 md:mb-7 font-bold">
                コーヒーとの出会い
              </h2>
              <p className="mb-7">
                フルーツやチョコレート、ナッツにスパイスなど
                <br />
                スペシャルティコーヒーは、多種多様な味わいを感じさせてくれます。
                <br />
                お好みのコーヒーとの出会い、コーヒーを通じて、新しい発見と喜びの体験。そんな経験が、小さな幸せを届けてくれます。
              </p>
              {windowWidth >= 768 && (
                <p>
                  みなさまとコーヒーとの出会いの一助になれたらと、私たちは考えています。
                </p>
              )}
            </div>
            <div className="md:w-[53.3%] mb-7 md:mb-0">
              <StaticImage
                src="../images/concept/image_02.png"
                alt="タイトル・キャッチコピー"
              />
            </div>
            {windowWidth < 768 && (
              <p>
                みなさまとコーヒーとの出会いの一助になれたらと、私たちは考えています。
              </p>
            )}
          </div>
        </div>
      </section>
      <section className="mb-[80px] md:mb-[140px]" id="show_detail">
        <div className="relative md:w-fit mx-auto">
          <div
            className="flex justify-center items-center text-xs md:text-sm pb-[10px]
            w-[142px] md:w-[190px] h-[80px] md:h-[113px] bg-concept-fukidashi font-bold antialiased
          bg-no-repeat bg-contain bg-center text-white absolute -top-[110px] md:-top-[126px]
          right-5 md:-right-[165px]"
          >
            クリックで詳細を表示！
          </div>
          <h2 className="text-md md:text-xxxl text-center font-bold antialiased mb-7 md:mb-[50px]">
            ログ コーヒーロースターズの
            <span className="text-[1.25em] text-primary">
              <DotChar
                dotColor="before:bg-primary"
                dotSize="before:w-[6px] before:h-[6px]"
              >
                こ
              </DotChar>
              <DotChar
                dotColor="before:bg-primary"
                dotSize="before:w-[6px] before:h-[6px]"
              >
                だ
              </DotChar>
              <DotChar
                dotColor="before:bg-primary"
                dotSize="before:w-[6px] before:h-[6px]"
              >
                わ
              </DotChar>
              <DotChar
                dotColor="before:bg-primary"
                dotSize="before:w-[6px] before:h-[6px]"
              >
                り
              </DotChar>
            </span>
          </h2>
        </div>
        <ConceptDetailBubbles selected={initialDetail} />
      </section>
      <section>
        <TextTitle>店舗情報</TextTitle>
        <BaseTableTemplate data={tableData} />
      </section>
    </BaseLayout>
  );
};

export default ConceptPage;
